import GameService from '../../services/games'

const mutations = {
  GET_GAME_HISTORY_REQUEST(state) {
    state.isGettingGameHistory = true
  },
  GET_GAME_HISTORY_SUCCESS(state, payload) {
    state.isGettingGameHistory = false
    state.gameHistory = payload
  },
  GET_GAME_HISTORY_FAILURE(state) {
    state.isGettingGameHistory = false
  },
  GET_GAME_HISTORY_DETAIL_REQUEST(state) {
    state.isGettingGameHistoryDetail = true
  },
  GET_GAME_HISTORY_DETAIL_SUCCESS(state, payload) {
    state.isGettingGameHistoryDetail = false
    state.gameHistoryDetail = payload
  },
  GET_GAME_HISTORY_DETAIL_FAILURE(state) {
    state.isGettingGameHistoryDetail = false
  },
}

const actions = {
  async getGameHistory({ commit, dispatch }) {
    try {
      commit('GET_GAME_HISTORY_REQUEST')
      const {data} = await GameService.getHistory()
      commit('GET_GAME_HISTORY_SUCCESS', data)
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('GET_GAME_HISTORY_FAILURE')
    }
  },
  async getGameHistoryDetail({ commit, dispatch }, id) {
    try {
      commit('GET_GAME_HISTORY_DETAIL_REQUEST')
      const {data} = await GameService.getHistoryDetail(id)
      commit('GET_GAME_HISTORY_DETAIL_SUCCESS', data)
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('GET_GAME_HISTORY_DETAIL_FAILURE')
    }
  },
}

const getters = {
  gameHistory: (state) => state.gameHistory,
  gameHistoryDetail: (state) => state.gameHistoryDetail,
}
const state = {
  isGettingGameHistory: false,
  gameHistory: [],

  isGettingGameHistoryDetail: false,
  gameHistoryDetail: {},
}
export default {
  state,
  mutations,
  actions,
  getters,
}
