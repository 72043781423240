import AssistantService from '../../services/assistant'

const mutations = {
  GET_ASSISTANTS_REQUEST(state) {
    state.isGettingAssistants = true
  },
  GET_ASSISTANTS_SUCCESS(state, payload) {
    state.isGettingAssistants = false
    state.assistants = payload
  },
  GET_ASSISTANTS_FAILURE(state) {
    state.isGettingAssistants = false
  },

  GET_ASSISTANT_REQUEST(state) {
    state.isGettingAssistantDetail = true
  },
  GET_ASSISTANT_SUCCESS(state, payload) {
    state.isGettingAssistantDetail = false
    state.assistantDetail = payload
  },
  GET_ASSISTANT_FAILURE(state) {
    state.isGettingAssistantDetail = false
  },

  CREATE_ASSISTANT_REQUEST(state) {
    state.isCreatingAssistant = true
  },
  CREATE_ASSISTANT_SUCCESS(state) {
    state.isCreatingAssistant = false
  },
  CREATE_ASSISTANT_FAILURE(state) {
    state.isCreatingAssistant = false
  },

  UPDATE_ASSISTANT_REQUEST(state) {
    state.isUpdatingAssistant = true
  },
  UPDATE_ASSISTANT_SUCCESS(state) {
    state.isUpdatingAssistant = false
  },
  UPDATE_ASSISTANT_FAILURE(state) {
    state.isUpdatingAssistant = false
  },
}
const actions = {
  async getAssistants({ commit, dispatch }) {
    try {
      commit('GET_ASSISTANTS_REQUEST')
      const response = await AssistantService.getAssistants()
      commit('GET_ASSISTANTS_SUCCESS', response)
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('GET_ASSISTANTS_FAILURE')
    }
  },
  async getAssistant({ commit, dispatch }, id) {
    try {
      commit('GET_ASSISTANT_REQUEST')
      const response = await AssistantService.getAssistantById(id)
      commit('GET_ASSISTANT_SUCCESS', response)
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('GET_ASSISTANT_FAILURE')
    }
  },
  async createAssistant({ commit, dispatch }, data) {
    try {
      commit('CREATE_ASSISTANT_REQUEST')
      const response = await AssistantService.createAssistant(data)
      commit('CREATE_ASSISTANT_SUCCESS', response)
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('CREATE_ASSISTANT_FAILURE')
    }
  },
  async updateAssistant({ commit, dispatch }, { id, data }) {
    try {
      commit('UPDATE_ASSISTANT_REQUEST')
      const response = await AssistantService.updateAssistant(id, data)
      commit('UPDATE_ASSISTANT_SUCCESS', response)
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('UPDATE_ASSISTANT_FAILURE')
    }
  },
}

const getters = {
  assistants: (state) => state.assistants,
  assistantDetail: (state) => state.assistantDetail,
}
const state = {
  isGettingAssistants: false,
  assistants: [],

  isGettingAssistantDetail: false,
  assistantDetail: '',

  isCreatingAssistant: false,
  isUpdatingAssistant: false,
}
export default {
  state,
  mutations,
  actions,
  getters,
}
