const mutations = {
  SET_ENVIRONMENT(state, value) {
    const envLocal = localStorage.getItem('environment')
    state.environment = value || envLocal || ''
  },
}
const actions = {
  async setEnvironment({ commit }, env) {
    localStorage.setItem('environment', env)
    commit('SET_ENVIRONMENT', env)
  },
}

const getters = {
  environment: (state) => state.environment,
}
const state = {
  environment: +localStorage.getItem('environment'),
}
export default {
  state,
  mutations,
  actions,
  getters,
}
