import RequestService from '../../services/requests'
import router from '../../router/index'

const mutations = {
  SEND_REQUEST_REQUEST(state) {
    state.isSendingRequest = true
  },
  SEND_REQUEST_SUCCESS(state) {
    state.isSendingRequest = false
  },
  SEND_REQUEST_FAILURE(state) {
    state.isSendingRequest = false
  },

  GET_REQUEST_DETAIL_REQUEST(state) {
    state.isGettingRequestDetail = true
  },
  GET_REQUEST_DETAIL_SUCCESS(state, payload) {
    state.isGettingRequestDetail = false
    state.requestDetail = payload
  },
  GET_REQUEST_DETAIL_FAILURE(state) {
    state.isGettingRequestDetail = false
  },

  GET_MY_REQUEST_REQUEST(state) {
    state.isGettingMyRequest = true
  },
  GET_MY_REQUEST_SUCCESS(state, payload) {
    state.isGettingMyRequest = false
    if (payload) {
      state.myRequests = payload
    }
  },
  GET_MY_REQUEST_FAILURE(state) {
    state.isGettingMyRequest = false
  },

  APPROVE_REQUEST_REQUEST(state) {
    state.isApprovingRequest = true
  },
  APPROVE_REQUEST_SUCCESS(state) {
    state.isApprovingRequest = false
  },
  APPROVE_REQUEST_FAILURE(state) {
    state.isApprovingRequest = false
  },

  EDIT_REQUEST_REQUEST(state) {
    state.isEditingRequest = true
  },
  EDIT_REQUEST_SUCCESS(state) {
    state.isEditingRequest = false
  },
  EDIT_REQUEST_FAILURE(state) {
    state.isEditingRequest = false
  },
}
const actions = {
  async sendRequest({ commit, dispatch }, data) {
    try {
      commit('SEND_REQUEST_REQUEST')
      await RequestService.sendRequest(data)
      commit('SEND_REQUEST_SUCCESS')
      dispatch('app/notifySuccess')
      if (router.currentRoute.name !== 'request') {
        router.push({ name: 'request' })
      }
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('SEND_REQUEST_FAILURE')
    }
  },
  async getMyRequests({ commit, dispatch }) {
    try {
      commit('GET_MY_REQUEST_REQUEST')
      const { data } = await RequestService.getMyRequests()
      commit('GET_MY_REQUEST_SUCCESS', data)
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('GET_MY_REQUEST_FAILURE')
    }
  },
  async getRequestDetail({ commit, dispatch }, id) {
    try {
      commit('GET_REQUEST_DETAIL_REQUEST')
      const response = await RequestService.getRequestById(id)
      commit('GET_REQUEST_DETAIL_SUCCESS', response)
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('GET_REQUEST_DETAIL_FAILURE')
    }
  },
  async editRequest({ commit, dispatch }, data) {
    try {
      commit('EDIT_REQUEST_REQUEST')
      await RequestService.editRequest(data)
      commit('EDIT_REQUEST_SUCCESS')
      router.push({ name: 'request' })
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('EDIT_REQUEST_FAILURE')
    }
  },
}

const getters = {
  requestDetail: (state) => state.requestDetail,
  myRequests: (state) => state.myRequests,
}
const state = {
  isSendingRequest: false,

  isGettingRequestDetail: false,
  requestDetail: {},

  isGettingMyRequest: false,
  myRequests: [],

  isEditingRequest: false,
}
export default {
  state,
  mutations,
  actions,
  getters,
}
