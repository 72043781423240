export default [
  {
    path: '/request_system',
    name: 'request_system',
    header: 'request_system',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    meta: {
      authRequired: true,
      isAppMenu: true,
    },
    children: [
      {
        key: 'request',
        path: '/request',
        name: 'request',
        route: 'request',
        icon: 'LinkIcon',
        component: () => import('@/views/request/index.vue'),
        meta: {
          authRequired: true,
          isAppMenu: true,
          icon: 'LinkIcon',
        },
      },
      {
        key: 'request_form',
        path: '/request/form',
        name: 'request_form',
        route: 'request_form',
        icon: 'LinkIcon',
        component: () => import('@/views/request/RequestForm.vue'),
        meta: {
          authRequired: true,
          isAppMenu: false,
          icon: 'LinkIcon',
          breadcrumb: [
            {
              name: 'request',
              to: '/request',
            },
            {
              name: 'request_form',
              active: true,
            },
          ],
        },
      },
      {
        key: 'request_detail',
        path: '/request/detail',
        name: 'request_detail',
        route: 'request_detail',
        icon: 'LinkIcon',
        component: () => import('@/views/request/Requested.vue'),
        meta: {
          authRequired: true,
          isAppMenu: false,
          icon: 'LinkIcon',
        },
      },
      {
        key: 'callback',
        path: '/callback',
        name: 'callback',
        route: 'callback',
        icon: 'CheckCircleIcon',
        component: () => import('@/views/callback/index.vue'),
        meta: {
          authRequired: true,
          isAppMenu: true,
          icon: 'CheckCircleIcon',
        },
      },
    ],
  },
]
