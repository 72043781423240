import axios from 'axios'

const baseURL = `${process.env.VUE_APP_API_URL}/assistants`

export default {
  getAssistants() {
    try {
      return axios.get(`${baseURL}`)
    } catch (e) {
      throw e
    }
  },
  createAssistant(data) {
    try {
      return axios.post(`${baseURL}/accounts/registration`, data)
    } catch (e) {
      throw e
    }
  },
  getAssistantById(id) {
    try {
      return axios.get(`${baseURL}/${id}`)
    } catch (e) {
      throw e
    }
  },
  updateAssistant(id, data) {
    try {
      return axios.patch(`${baseURL}/${id}`, data)
    } catch (e) {
      throw e
    }
  },
}
