<template>
  <div class="dataTables_paginate paging_simple_numbers float-right">
    <ul class="pagination pagination-rounded mb-0 mt-2">
      <b-pagination
        v-model="page"
        :total-rows="total"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
      <b-form-input
        v-model="page"
        style="width: 40px; height: 30px"
        class="ml-1"
      />
    </ul>
  </div>
</template>

<script>

export default {
  name: 'PaginationInput',
  props: {
    currentPage: {
      type: Number,
      default: null,
    },
    total: {
      type: Number,
      required: true,
      default: 0,
    },
    perPage: {
      type: Number,
      required: true,
      default: 50,
    },
  },
  data() {
    return {
      page: 1,
    }
  },
  watch: {
    page(val) {
      if (val) {
        this.$emit('update', val)
      }
    },
  },
  created() {
    if(this.currentPage){
      this.page = this.currentPage
    }
  },
}
</script>