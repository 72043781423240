export default [
  {
    key: 'settings',
    path: '/settings',
    name: 'settings',
    route: 'settings',
    header: 'settings',
    meta: {
      authRequired: true,
      isAppMenu: true,
    },
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        key: 'agent:view.assistants',
        path: '/assistants',
        name: 'assistants',
        route: 'assistants',
        icon: 'UsersIcon',
        component: () => import('@/views/assistants/AssistantList.vue'),
        meta: {
          authRequired: true,
          isAppMenu: true,
          breadcrumb: [],
          icon: 'UsersIcon',
        },
      },
      {
        key: 'agent:view.account',
        path: '/account',
        name: 'account',
        route: 'account',
        icon: 'UserIcon',
        component: () => import('@/views/dashboard/analytics/Analytics.vue'),
        meta: {
          authRequired: true,
          isAppMenu: true,
          icon: 'UserIcon',
        },
      },
      {
        key: 'agent:view.activity',
        path: '/activity',
        name: 'activity',
        route: 'activity',
        icon: 'ClipboardIcon',
        component: () => import('@/views/dashboard/analytics/Analytics.vue'),
        meta: {
          authRequired: true,
          isAppMenu: true,
          icon: 'ClipboardIcon',
        },
      },
    ],
  },
]
