import axios from 'axios'

const baseURL = `${process.env.VUE_APP_API_URL}/integration-requests`

export default {
  sendRequest(data) {
    try {
      return axios.post(`${baseURL}`, data)
    } catch (e) {
      throw e
    }
  },
  getMyRequests() {
    try {
      return axios.get(`${baseURL}/me`)
    } catch (e) {
      throw e
    }
  },
  getRequestById(id) {
    try {
      return axios.get(`${baseURL}/${id}`)
    } catch (e) {
      throw e
    }
  },
  editRequest(data) {
    try {
      return axios.put(`${baseURL}`, data)
    } catch (e) {
      throw e
    }
  },
}
