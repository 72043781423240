import Vue from 'vue'
// axios
import axios from 'axios'
import UserService from '../services/user'
import store from '../store'
import router from '../router/index'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  timeout: 15000,
})

axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    const userToken = UserService.getAccessToken()
    if (userToken) {
      config.headers.authorization = `Bearer ${userToken}`
    }
    return config
  },
  (error) => Promise.reject(error)
)
// Your Interceptor code to do something with the response data
// Return response data
axios.interceptors.response.use(
  (response) => {
    if (response.data instanceof Blob) {
      return response.data
    }
    return response.data.is_success && response.data.data
      ? response.data.data
      : response.data
  },
  (error) => {
    // Your Interceptor code to do something with response error
    const { status } = error.response
    if (status && status === 401) {
      store.dispatch('logout')
    }
    if (status && status === 403) {
      router.replace('/dashboard')
    }
    return Promise.reject(
      error.response.data && !error.response.data.is_success
        ? error.response.data.message
        : status || error
    )
  }
)

Vue.prototype.$http = axiosIns

export default axiosIns
