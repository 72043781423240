import router from '../../router/index'
import UserService from '../../services/user'

const mutations = {
  GET_ME_REQUEST(state) {
    state.isGettingMe = true
  },
  GET_ME_SUCCESS(state, payload) {
    state.isGettingMe = false
    state.isGettingMeError = true
    state.userInfo = payload
  },
  GET_ME_FAILURE(state) {
    state.isGettingMe = false
    state.isGettingMeError = true
  },
  REGISTER_REQUEST(state) {
    state.isRegistering = true
  },
  REGISTER_SUCCESS(state) {
    state.isRegistering = false
  },
  REGISTER_FAILURE(state) {
    state.isRegistering = false
  },

  RESET_PASSWORD_REQUEST(state) {
    state.isResettingPassword = true
    state.isResetPasswordSuccess = false
    state.isResetPasswordFailed = false
  },
  RESET_PASSWORD_SUCCESS(state) {
    state.isResettingPassword = false
    state.isResetPasswordSuccess = true
  },
  RESET_PASSWORD_FAILURE(state) {
    state.isResettingPassword = false
    state.isResetPasswordFailed = true
  },

  RESET_PASSWORD_WITH_TOKEN_REQUEST(state) {
    state.isResettingPasswordWithToken = true
    state.isResetPasswordWithTokenSuccess = false
    state.isResetPasswordWithTokenFailed = false
  },
  RESET_PASSWORD_WITH_TOKEN_SUCCESS(state) {
    state.isResettingPasswordWithToken = false
    state.isResetPasswordWithTokenSuccess = true
  },
  RESET_PASSWORD_WITH_TOKEN_FAILURE(state) {
    state.isResettingPasswordWithToken = false
    state.isResetPasswordWithTokenFailed = true
  },
}

const actions = {
  async getMe({ commit, dispatch }) {
    try {
      commit('GET_ME_REQUEST')
      const response = await UserService.getMe()
      commit('GET_ME_SUCCESS', response)
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('GET_ME_FAILURE')
    }
  },
  async register({ commit, dispatch }, data) {
    try {
      commit('REGISTER_REQUEST')
      const result = await UserService.signup(data)
      const token = result?.data?.token || result?.token
      if (token) {
        UserService.saveAccessToken(token)
        commit('REGISTER_SUCCESS', result)
        router.replace('/register-success')
      }
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('REGISTER_FAILURE')
    }
  },

  async resetPassword({ commit, dispatch }, email) {
    try {
      commit('RESET_PASSWORD_REQUEST')
      await UserService.resetPassword(email)
      commit('RESET_PASSWORD_SUCCESS')
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('RESET_PASSWORD_FAILURE')
    }
  },

  async resetPasswordWithToken({ commit, dispatch }, { token, data }) {
    try {
      commit('RESET_PASSWORD_WITH_TOKEN_REQUEST')
      await UserService.resetPasswordWithToken(token, data)
      commit('RESET_PASSWORD_WITH_TOKEN_SUCCESS')
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('RESET_PASSWORD_WITH_TOKEN_FAILURE')
    }
  },
}

const getters = {
  userInfo: (state) => state.userInfo,
}

const state = {
  userInfo: { username: '' },
  isGettingMe: false,
  isGettingMeError: false,

  isRegistering: false,

  isResettingPasswordWithToken: false,
  isResetPasswordWithTokenSuccess: false,
  isResetPasswordWithTokenFailed: false,

  isResettingPassword: false,
  isResetPasswordSuccess: false,
  isResetPasswordFailed: false,
}

export default {
  state,
  mutations,
  actions,
  getters,
}
