import axios from 'axios'

const baseURL = `${process.env.VUE_APP_API_URL}`

export default {
  getHistory() {
    try {
      return axios.get(`${baseURL}/history/me`)
    } catch (e) {
      throw e
    }
  },
  getHistoryDetail(id) {
    try {
      return axios.get(`${baseURL}/${id}`)
    } catch (e) {
      throw e
    }
  },
}
